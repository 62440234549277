import React from 'react'
import Layout from '../components/Layout';
import ComingSoon from '../components/ComingSoon';

const Company = () => {
  return (
    <Layout title='Company'>
       <section>
            <ComingSoon/>
        </section>
    </Layout>
  )
}

export default Company;

