import React from 'react'
import Layout from '../components/Layout'

const ForgotPassword = () => {
  return (
         <Layout>
            <section>
              <div className='mt-[140px]'>
            <form class="max-w-md mx-auto">
            <div className='flex'>
            <p className="m-auto mt-0 text-[24px] justify-center font-normal text-gray-500 max-w-3xl leading-[38px] py-0 px-4 max-md:text-[18px] max-md:leading-[28px] max-md:max-w-xl max-sm:text-[16px] max-sm:leading-[26px]">Forgot Password</p>
            </div>
                <div class="relative z-0 w-full mb-5 group">
                    <input type="email" name="floating_email" id="floating_email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-500 dark:focus:border-gray-200 focus:outline-none focus:ring-0 focus:border-gray-200 peer" placeholder=" " required />
                    <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-200 peer-focus:dark:text-gray-200 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
                </div>
                <button type="submit" class="text-white bg-custom-gradient focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center">Submit</button>
              </form>
            </div>
            </section>
         </Layout>
  )
}

export default ForgotPassword