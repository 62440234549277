import React from 'react'
import Layout from '../components/Layout.js'
import ComingSoon from '../components/ComingSoon.js'

const Resources = () => {
  return (
        <Layout title='Resources'>
          <section>
              <ComingSoon/>
          </section>
        </Layout>
  )
}

export default Resources