import React, { useState } from 'react';
import '../tailwind.css';
import Layout from '../components/Layout';

const ParkProDemoVidsPage = () => {

  return (
    <Layout>
        <div className='flex items-center justify-center place-content-center'>
            <div className='max-w-screen-lg flex flex-col p-7'>
                <h1 className='pb-8 text-gray-300 text-xl md:text-2xl lg:text-3xl'>Park Pro App Demo Videos</h1>

                <div className='flex flex-col pb-28'>
                    <div className='video-container'>
                        <iframe width="288" height="162" src="https://www.youtube-nocookie.com/embed/Rjg-GAmKOf0?si=nnKZ6JV3gA7P8GZ3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <h2 className='text-lg md:text-xl lg:text-2xl text-gray-400 my-4 tracking-wider'>Host - Create Property</h2>
                    <div className='text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light'>Adding properties is the first step for a Host to start generating revenue. The Park Pro App leads Hosts through a step by step process that collects all relevant information needed to list a property. We allow the host to find their property in a map and allow them to define the Parking area as well as an entry and exit point for the parking lot, so that drivers can successfully find where they should park on a Host's property. After creating this property, a Park Pro Admin will receive a notification to review the property. Once they accept, then the Host can set their property live and define the property's availability.</div>
                </div>

                <div className='flex flex-col pb-28'>
                    <div className='video-container'>
                        <iframe width="288" height="162" src="https://www.youtube-nocookie.com/embed/wdoNp4g_ZKc?si=3BRCPcklBGkLbCBw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <h2 className='text-lg md:text-xl lg:text-2xl text-gray-400 my-4 tracking-wider'>Host - Edit Property</h2>
                    <div className='text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light'>Hosts may need to go back and edit details on their property listings. They can find this on their Properties dashboard page. They will be guided through the property set up flow where they can edit any details they wish.</div>
                </div>

                <div className='flex flex-col pb-28'>
                    <div className='video-container'>
                        <iframe width="288" height="162" src="https://www.youtube.com/embed/BvjYyfhGBGA?si=Li9Udzyxa5rczbsh" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <h2 className='text-lg md:text-xl lg:text-2xl text-gray-400 my-4 tracking-wider'>Host - Edit Property Availability</h2>
                    <div className='text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light'>Hosts can update their property availability at any time on their Dashboard. They can edit the days of the week that their property is open to drivers and the specific times of day as well. They can even edit specific days in the future if there are special black out dates or limited hours during a specific date. This could be due to an event taking place or even a holiday. This menu is also where Hosts can set their property live after a Park Pro Admin approves their listing.</div>
                </div>

                <div className='flex flex-col pb-28'>
                    <div className='video-container'>
                        <iframe width="288" height="162" src="https://www.youtube-nocookie.com/embed/L4bngCAZX3Q?si=Y8umFvcrSEyn6mYS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <h2 className='text-lg md:text-xl lg:text-2xl text-gray-400 my-4 tracking-wider'>Host - Approve Reservation</h2>
                    <div className='text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light'>After Carriers or Fleet Managers book parking reservations for a given Driver, the Host is notified to review the reservation request. The Host can then approve the Reservation, which would then send a notification to the Carrier, Fleet Manager, and Driver that the reservation was approved.</div>
                </div>

                <div className='flex flex-col pb-28'>
                    <div className='video-container'>
                        <iframe width="288" height="162" src="https://www.youtube-nocookie.com/embed/cSN3bhEkPuU?si=RRYC07JC3V7rYFnE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <h2 className='text-lg md:text-xl lg:text-2xl text-gray-400 my-4 tracking-wider'>Host - Cancel Reservation</h2>
                    <div className='text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light'>Conflicts happen or disasters happen. In that event the Park Pro App allows Hosts the ability to cancel Parking Reservations that Carriers or Fleet Managers book for Drivers. In this event the Carrier, Fleet Manager, Driver, and the Host will receive notification or confirmation that the Cancellation happened. The Carrier is refunded for cancellations made by the Host.</div>
                </div>

                <div className='flex flex-col pb-28'>
                    <div className='video-container'>
                        <iframe width="288" height="162" src="https://www.youtube-nocookie.com/embed/wdoNp4g_ZKc?si=3BRCPcklBGkLbCBw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <h2 className='text-lg md:text-xl lg:text-2xl text-gray-400 my-4 tracking-wider'>Host - Add Payout Account</h2>
                    <div className='text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light'>Time to get paid! Hosts can easily add a bank account to their Park Pro account. This will allow them to get paid for all the reservations made at their properties.</div>
                </div>

                <div className='flex flex-col pb-28'>
                    <div className='video-container'>
                        <iframe width="288" height="162" src="https://www.youtube-nocookie.com/embed/fkOsSBNy_dA?si=7Jr2dn8Xb1oNKeaO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <h2 className='text-lg md:text-xl lg:text-2xl text-gray-400 my-4 tracking-wider'>Carrier - Create Account</h2>
                    <div className='text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light'>Naturally, the first step for a Carrier is to sign up for a Park Pro Account. During this account creation process, the Park Pro App collects information about the Carrier's company as well as their personal information so that the person setting up the Carrier account will have a profile with contact information shall the Park Pro team ever need to contact the Carrier.</div>
                </div>

                <div className='flex flex-col pb-28'>
                    <div className='video-container'>
                        <iframe width="288" height="162" src="https://www.youtube-nocookie.com/embed/3ejNHgA5RyU?si=akmJFKstT13y_Zy3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <h2 className='text-lg md:text-xl lg:text-2xl text-gray-400 my-4 tracking-wider'>Carrier - Create Fleet</h2>
                    <div className='text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light'>Carriers are able to create Fleets that they are responsible for to their Park Pro account. During this process they define Fleet Managers and Drivers that belong to those fleets that the Fleet Manager manages. Carriers need at least 1 Fleet to be able to book Parking Reservations. This process kicks off emails to the Fleet Managers and Drivers to sign up for a Park Pro app.</div>
                </div>

                <div className='flex flex-col pb-28'>
                    <div className='video-container'>
                        <iframe width="288" height="162" src="https://www.youtube-nocookie.com/embed/Dd71mJjyZLg?si=6PeSatQlu-WdDSiQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <h2 className='text-lg md:text-xl lg:text-2xl text-gray-400 my-4 tracking-wider'>Carrier - Add Driver to Fleet</h2>
                    <div className='text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light'>Carriers are able to add Drivers to an existing Fleet. They are also able to delete Drivers from an existing Fleet on this Fleets page on their Dashboard. New Drivers add will be sent an email to sign up for the Park Pro App.</div>
                </div>

                <div className='flex flex-col pb-28'>
                    <div className='video-container'>
                        <iframe width="288" height="162" src="https://www.youtube-nocookie.com/embed/1U4Jr01ol34?si=wc0_WShxkHSCLMvU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <h2 className='text-lg md:text-xl lg:text-2xl text-gray-400 my-4 tracking-wider'>Fleet Manager - Book Reservation</h2>
                    <div className='text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light'>Fleet Managers can book Parking Reservations for their Drivers. They can do this by navigating to a property and booking on the property's page. The Fleet Manager will be asked to provide the date in which they want to book and the time amount by hour. This booking will kick off a notification to the Host to approve or deny the booking request. Once approved the Carrier, Fleet Manager, Driver, and Host will be notified with a reservation confirmation.</div>
                </div>
                
                <div className='flex flex-col pb-28'>
                    <div className='video-container'>
                        <iframe width="288" height="162" src="https://www.youtube-nocookie.com/embed/lHh0VeE2fPM?si=gc6Hj1E4YBdrFPV4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <h2 className='text-lg md:text-xl lg:text-2xl text-gray-400 my-4 tracking-wider'>Park Pro Admin - Approve Property</h2>
                    <div className='text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light'>Once the Host submits a new property, a Park Pro Admin will be notified that they have a property to review. The Park Pro Admin will be able to accept this property submission, at which point the Host would then be notified that their proprety is ready to be set live with availability defined. The Park Pro Admin is also able to reject the property with a message back to the Host for them to fix. This could be due to the Parking Lot Map being incorrect, due to insufficent parking instructions, or because the property listing is lacking helpful pictures.</div>
                </div>
                
                <div className='flex flex-col'>
                    <div className='video-container'>
                        <iframe width="288" height="162" src="https://www.youtube-nocookie.com/embed/Xeroux6V7NU?si=Z1IyXK1w3ud_jF2l" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <h2 className='text-lg md:text-xl lg:text-2xl text-gray-400 my-4 tracking-wider'>All Users - Add Payment Method</h2>
                    <div className='text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light'>Whether it's a credit card or a bank account, the Park Pro app allows Carriers, Fleet Managers, and Hosts to define payment methods. For Hosts, they will want to add their bank account information so that they can get paid out. Carriers and Fleet Managers can add credit cards or debit cards to the Park Pro App so that they can use that as an option to pay for their parking reservations.</div>
                </div>
            </div>
        </div>
    </Layout>
  );

}

export default ParkProDemoVidsPage;