import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/summitml-icon-white.svg';
import XIcon from '../assets/x-icon.svg';
import InstagramIcon from '../assets/instagram-icon.svg';

const Footer = () => {
    const [year, setYear] = useState(new Date().getFullYear());

    useEffect(() => {
        setYear(new Date().getFullYear());
    } ,[]);

    return (
        <footer className="text-gray-500 border-t border-gray-700 flex flex-col items-center gap-5 py-14 px-5">
            <img src={logo} className="h-8" alt="Summit ML Logo" />
            <div className="m-10 flex gap-16 flex-wrap justify-center">
                <Link to="/solutions" className='transition-colors duration-300 hover:text-white'>Solutions</Link>
                <Link to="/products" className='transition-colors duration-300 hover:text-white'>Products</Link>
                <Link to="/resources" className='transition-colors duration-300 hover:text-white'>Resources</Link>
                <Link to="/company" className='transition-colors duration-300 hover:text-white'>Company</Link>
                <Link to="/login" className='transition-colors duration-300 hover:text-white'>Login</Link>
                <Link to="/contact" className='transition-colors duration-300 hover:text-white'>Contact</Link>
            </div>
            <div className="text-[12px]">
                Copyright &copy; {year} Summit ML. All rights reserved. Colorado.
            </div>
            <div className="mt-2 flex gap-5">
                <a href="https://www.instagram.com/summit_ml/" target="_blank" rel="noreferrer">
                    <img src={InstagramIcon} className="h-5" alt="Instagram Icon" />
                </a>
                <a href="https://www.x.com/summit_ml" target="_blank" rel="noreferrer">
                    <img src={XIcon} className="h-5" alt="X Icon" />
                </a>
            </div>
        </footer>
    );
}

export default Footer;
