import React from "react";
import Layout from "../components/Layout.js";
import Button from "../components/Button.js";

const Contact = () => {
    return (
        <Layout title='Contact'>
            <section>
                <div class="py-12 lg:py-24 px-4 mx-auto max-w-screen-xl">
                    <h2 class="mb-6 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
                        Ready to chat?
                    </h2>
                    <p class="mb-1 lg:mb-2 font-light text-center text-gray-500 dark:text-gray-400 sm:text-lg">
                        Our LLMs don't just process data—they're eagerly
                        awaiting your message.
                    </p>
                    <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-lg">
                        Drop a line and let's engineer the future together.
                    </p>
                    <form
                        action="https://api.olokoo.com/forms/submit"
                        name="contact"
                        method="post"
                        className="space-y-8 max-w-xl mx-auto mt-[70px]"
                    >
                        <input
                            type="hidden"
                            name="honeypotfieldname"
                            value="bot-field"
                        />
                        <input
                            type="hidden"
                            name="redirectsuccess"
                            value="https://summitml.com/contact/success"
                        />
                        <input
                            type="hidden"
                            name="redirecterror"
                            value="https://summitml.com/contact/error"
                        />
                        <input
                            type="hidden"
                            name="olokooaccesstoken"
                            value="ODAxYTlmNzYtYzg2OS0xMWVlLWE4YmEtMTJkOGZmNGQ3NDE1"
                        />
                        <input
                            type="hidden"
                            name="olokoointegration"
                            value="1df95f68-c868-11ee-9fe4-12d8ff4d7415"
                        />
                        <input type="hidden" name="bot-field" />
                        <div class="relative z-0 w-full mb-5 group">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                class="rounded-none block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-500 dark:focus:border-gray-200 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                placeholder=" "
                                required
                            />
                            <label
                                for="email"
                                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-200 peer-focus:dark:text-gray-200 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Email address
                            </label>
                        </div>
                        <div class="relative z-0 w-full mb-5 group">
                            <input
                                type="text"
                                name="name"
                                id="name"
                                class="rounded-none block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-500 dark:focus:border-gray-200 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                placeholder=" "
                                required
                            />
                            <label
                                for="name"
                                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-200 peer-focus:dark:text-gray-200 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Your name
                            </label>
                        </div>
                        <div class="relative z-0 w-full mb-5 group">
                            <textarea
                                rows="6"
                                type="message"
                                name="message"
                                id="message"
                                class="rounded-none block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-500 dark:focus:border-gray-200 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                                placeholder=" "
                                required
                            />
                            <label
                                for="message"
                                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-200 peer-focus:dark:text-gray-200 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Message
                            </label>
                        </div>
                        <Button
                            text="Submit"
                            variant="gradient"
                            shadow={false}
                            fullWidth={true}
                        />
                    </form>
                </div>
            </section>
        </Layout>
    );
};

export default Contact;
