import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module'

import Company from './pages/Company.js';
import Home from './pages/Home.js';
import NotFoundPage from './pages/NotFoundPage.js';
import ForgotPassword from './pages/ForgotPassword.js';
import CreateAccount from './pages/CreateAccount.js';
import Contact from './pages/Contact.js';
import Login from './pages/Login.js';
import Products from './pages/Products.js';
import Resources from './pages/Resources.js';
import Solutions from './pages/Solutions.js';
import ParkProDemoVidsPage from './pages/ParkProDemoVids.js';

const tagManagerArgs = { gtmId: 'GTM-TQJ2ZJ6J' };
TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <>
    <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/create-account" element={<CreateAccount />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/products" element={<Products />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/contact" element={<Contact />}/>
            <Route path="/contact" >
              <Route path=':topic' element={<Contact />}/>
              </Route>
            <Route path="/company" element={<Company />}/>
            <Route path="/" element={<Home />} /> {/* Default Route */}
            <Route path="/clients/parkpro/demo" element={<ParkProDemoVidsPage />} />
            <Route path="*" element={<NotFoundPage />} /> {/* 404 */}
          </Routes>
        </Router>
    </>
  );
}

export default App;
