import React, { useEffect, useRef, useState } from 'react'
import Layout from '../components/Layout.js'
import Button from '../components/Button.js'

const Solutions = () => {
  const [activeSection, setActiveSection] = useState(null);
  const overviewRef = useRef(null);
  const dataAnalysisRef = useRef(null);
  const appDevelopmentRef = useRef(null);
  const fineTuningRef = useRef(null);
  const modelWrappersRef = useRef(null);

  const sections = [
    { name: 'overview', ref: overviewRef },
    { name: 'dataAnalysis', ref: dataAnalysisRef },
    { name: 'appDevelopment', ref: appDevelopmentRef },
    { name: 'fineTuning', ref: fineTuningRef },
    { name: 'modelWrappers', ref: modelWrappersRef },
  ];

  const handleScroll = () => {
    const currentSection = sections.find(section => {
      const element = section.ref.current;
      if (element) {
        const { offsetTop } = element;
        const screenPosition = window.scrollY + window.innerHeight / 2;
        return screenPosition > offsetTop && element.getBoundingClientRect().bottom >= 50;
      }
      return false;
    });

    setActiveSection(currentSection ? currentSection.name : null);
  };

  const handleClick = (jumpToSectionRef) =>{
    jumpToSectionRef.current.scrollIntoView({ behavior: 'smooth' })
    const currentSection = sections.find(section =>{
      return section.ref === jumpToSectionRef;
    })
    setActiveSection(currentSection.name)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  return (
      <Layout title="Solutions" marginBottomClass={"mb-0"}>
          <section className="xl:px-12 font-mono">
              <h1 className="text-white mx-auto mt-[50px] mb-[50px] text-[50px] tracking-wide max-md:text-[30px] max-md:mt-[30px] max-md:mb-[50px] md:max-lg:text-[30px]">
                  <span className="text-gradient from-purple-600 to-pink-600 pl-1.5 pr-1.5">
                      Partners in
                  </span>
                  <br />
                  <span className="text-gradient from-purple-600 to-pink-600 pl-1.5 pr-1.5">
                      machine learning
                  </span>
              </h1>
              <div className="flex flex-col md:flex-row border-t border-gray-700">
                  <div className=" hidden w-0 lg:h-full lg:sticky lg:top-[90px] lg:w-1/4 lg:justify-left lg:items-center lg:flex lg:flex-1 lg:text-2xl lg:mt-4">
                      <ul>
                          <li
                              className={`${
                                  activeSection === "overview"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(overviewRef)}
                          >
                              Overview
                          </li>
                          <li
                              className={`${
                                  activeSection === "dataAnalysis"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(dataAnalysisRef)}
                          >
                              Data Analysis
                          </li>
                          <li
                              className={`${
                                  activeSection === "appDevelopment"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(appDevelopmentRef)}
                          >
                              Application Development
                          </li>
                          <li
                              className={`${
                                  activeSection === "fineTuning"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(fineTuningRef)}
                          >
                              LLM Fine-Tuning
                          </li>
                          <li
                              className={`${
                                  activeSection === "modelWrappers"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(modelWrappersRef)}
                          >
                              Model Wrappers
                          </li>
                      </ul>
                  </div>
                  <div className="w-full pl-1.5 lg:border-l lg:border-gray-700 lg:w-3/4 lg:pl-10">
                      <div id="overview" ref={overviewRef} className="pt-16">
                          <h2 className="text-lg md:text-xl lg:text-2xl text-gray-500 my-4 tracking-wider">
                              Overview
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                              At Summit ML, we're pioneering the path of
                              innovative Machine Learning (ML) solutions from
                              the vibrant tech landscape of Colorado to every
                              corner of the continental United States. Our team
                              of ML experts is dedicated to harnessing the power
                              of data to fuel growth, innovation, and
                              transformation for businesses across various
                              industries. With a strong focus on collaboration
                              and customization, we offer a suite of services
                              designed to meet the unique needs of each client.
                              From insightful data analysis to bespoke
                              application development, and cutting-edge LLM
                              fine-tuning, our solutions are crafted to elevate
                              your business to its peak performance.
                          </p>
                      </div>
                      <div
                          id="data-analysis"
                          ref={dataAnalysisRef}
                          className="pt-16"
                      >
                          <h2 className="text-lg md:text-xl lg:text-2xl text-gray-500 my-4 tracking-wider">
                              Data Analysis
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                              Unlock the full potential of your data with Summit
                              ML's comprehensive Data Analysis services. Our
                              expert team employs the latest techniques and
                              tools to extract meaningful insights from complex
                              datasets. Whether you're looking to understand
                              customer behavior, optimize operational
                              efficiency, or forecast market trends, our data
                              analysis solutions provide the clarity and
                              precision you need to make informed decisions. Let
                              us help you turn your data into a strategic asset.
                          </p>
                      </div>
                      <div
                          id="app-development"
                          ref={appDevelopmentRef}
                          className="pt-16"
                      >
                          <h2 className="text-lg md:text-xl lg:text-2xl text-gray-500 my-4 tracking-wider">
                              Application Development
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                              In the digital age, custom software solutions are
                              a key differentiator for businesses seeking to
                              innovate and excel. Summit ML specializes in
                              developing tailored applications that integrate
                              seamlessly with your existing systems and
                              workflows. From automating mundane tasks to
                              creating intelligent interfaces that anticipate
                              user needs, our Application Development services
                              are at the forefront of leveraging ML technologies
                              to solve real-world problems. Engage us to build
                              robust, scalable, and intuitive applications that
                              drive productivity and growth.
                          </p>
                      </div>
                      <div
                          id="fine-tuning"
                          ref={fineTuningRef}
                          className="pt-16"
                      >
                          <h2 className="text-lg md:text-xl lg:text-2xl text-gray-500 my-4 tracking-wider">
                              LLM Fine-Tuning
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                              Harness the power of AI language understanding
                              with Summit ML's LLM Fine-Tuning services. Our
                              expertise extends to customizing state-of-the-art
                              language models to suit your specific business
                              requirements. Whether it's enhancing customer
                              service bots, generating dynamic content, or
                              improving natural language processing
                              capabilities, our fine-tuning process ensures that
                              the LLMs deliver optimal performance and
                              relevance. Partner with us to transform how your
                              business interacts with and processes natural
                              language data.
                          </p>
                      </div>
                      <div
                          id="model-wrappers"
                          ref={modelWrappersRef}
                          className="pt-16"
                      >
                          <h2 className="text-lg md:text-xl lg:text-2xl text-gray-500 my-4 tracking-wider">
                              Model Wrappers
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                              Summit ML's Model Wrappers service is designed to
                              seamlessly integrate advanced ML models into your
                              existing technology stack. Our wrappers provide a
                              simplified interface to complex ML
                              functionalities, making it easier for your
                              applications to leverage predictive analytics,
                              image recognition, natural language processing,
                              and more. By abstracting the intricacies of model
                              integration, we enable your team to focus on
                              creating innovative solutions without the need for
                              deep ML expertise. Let our model wrappers be the
                              bridge between your technological infrastructure
                              and the cutting-edge capabilities of machine
                              learning.
                          </p>
                      </div>
                      <div className="pt-16 pb-24">
                          <Button
                              text="Contact Us"
                              link="/contact"
                              variant="gradient"
                              shadow={true}
                          />
                      </div>
                  </div>
              </div>
          </section>
      </Layout>
  );
}

export default Solutions