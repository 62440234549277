import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => {
  return (
    <Layout title='Page Not Found'>
      Whoops
    </Layout>
  )
}

export default NotFoundPage