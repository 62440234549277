import React, { useState } from 'react'
import Layout from '../components/Layout.js'
import Button from '../components/Button.js';

const Login = () => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
      setError('');
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      setError('Invalid password. Please try again.');
    };
  return (
      <Layout title='Login'>
          <section>
              <form class="max-w-xl mx-auto my-40 px-4" onSubmit={handleSubmit}>
                  <h2 class="mb-6 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
                    Client Log In
                  </h2>
                  <div class="relative z-0 w-full mb-5 group">
                      <input
                          type="email"
                          name="email"
                          id="email"
                          class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-500 dark:focus:border-gray-200 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                          placeholder=" "
                          required
                      />
                      <label
                          for="email"
                          class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-gray-200 peer-focus:dark:text-gray-200 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                          Email address
                      </label>
                  </div>
                  <div class="relative z-0 w-full mb-8 group">
                      <input
                          type="password"
                          name="password"
                          id="password"
                          class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-500 dark:focus:border-gray-200 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                          placeholder=" "
                          value={password}
                          onChange={handlePasswordChange}
                          required
                      />
                      <label
                          for="password"
                          class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-gray-200 peer-focus:dark:text-gray-200 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                          Password
                      </label>
                  </div>
                  {error && <div className="mb-5 text-sm text-red-600">{error}</div>}
                  <Button
                      text="Submit"
                      variant="gradient"
                      shadow={false}
                      fullWidth={true}
                  />
                  <p className="mt-5 text-sm text-gray-500 text-center">
                    If you have trouble logging in, please contact your Summit ML representative.
                  </p>
              </form>
          </section>
      </Layout>
  );
}

export default Login;
