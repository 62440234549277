import React from 'react'
import Layout from '../components/Layout.js';
import ComingSoon from '../components/ComingSoon.js';

const Products = () => {
  return (
      <Layout title='Products'>
         <section>
         <ComingSoon/>
        </section>
      </Layout>
  )
}

export default Products;
